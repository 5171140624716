import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import HeroButton from "./Buttons/HeroButton";
import Bubbles from "./PixiNext/Bubbles";
import LoaderModal from "./Modal/LoaderModal";

import {
  setHideLoader,
  setSelectedFilter,
  setShowLoader,
} from "../reducers/slices/SettingsSlice";
import { setDailyStocks } from "../reducers/slices/StockSlice";

// Function to transform all data
const transformAllData = (dataArray) => {
  return dataArray.map((item, index) => ({
    id: item.id || index.toString(),
    symbol: item.Symbol || 'N/A',
    dailyPerformance: item.dailyPerformance ?? item.PerChange ?? 0,
    weeklyPerformance: item.weeklyPerformance ?? item.WeeklyChange ?? 0,
    monthlyPerformance: item.monthlyPerformance ?? item.MonthChange ?? 0,
    yearlyPerformance: item.yearlyPerformance ?? item.YearChange ?? 0,
    yearToDatePerformance: item.yearToDatePerformance ?? item.YTDChange ?? 0,
    volume: item.volume ?? 0,
    marketCap: item.marketCap ?? 0,
    rank: item.rank ?? index + 1,
    // Map other fields as needed
  }));
};

const BubbleSection = () => {
  const dispatch = useDispatch();

  // Selectors to access Redux state
  const colorScheme = useSelector((state) => state.Settings.colorScheme);
  const loading = useSelector((state) => state.Settings.loader);
  const activeButton = useSelector((state) => state.Settings.selectedFilter);
  const selectedSector = useSelector((state) => state.Settings.selectedSector);
  const selectedAsset = useSelector((state) => state.Settings.selectedAsset);
  const allData = useSelector((state) => state.Settings.allData);
  const selectedPagination = useSelector((state) => state.Stocks.selectedPage);


  useEffect(() => {
    const fetchData = async () => {
      let alpha = true;
      let count = 1;
      try {
        dispatch(setShowLoader());
        while (alpha) {
          const filters = {
            endDate: moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
            startDate: moment()
              .subtract(count, "days")
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ss"),
            volume: true,
          };

          if (selectedSector !== "ALL SECTORS") {
            filters.sector = selectedSector;
          }
          if (selectedAsset) {
            filters.asset = selectedAsset.id;
          }

          // Filter stocks based on selected asset and sector
          const filteredStocks = allData.filter((item) => {
            if (selectedAsset.name === "Equity") {
              if (selectedSector === "ALL SECTORS") {
                return item.Asset === "EQUITY";
              } else {
                return (
                  item.Asset === "EQUITY" && item.Sector === selectedSector
                );
              }
            } else if (selectedAsset.name === "Debt") {
              return item.Asset === "DEBT";
            } else if (selectedAsset.name === "ETF") {
              return item.Asset === "ETF";
            } else if (selectedAsset.name === "All") {
              return true;
            }
            return false;
          });

          const transformedAllData = transformAllData(filteredStocks);
          console.log(
            transformedAllData,
            "transformedAllData",
            filteredStocks,
            "filteredStocks"
          );

          // Sort the transformed data based on the selected filter
          const sortedData = [...transformedAllData].sort((a, b) => {
            // Handle undefined or null values
            const aValue = a[activeButton] || 0;
            const bValue = b[activeButton] || 0;
            return bValue - aValue; // Descending order
          });

          // Use the sorted data
          const combinedStocks = sortedData;

          if (combinedStocks.length) {
            const [startStr, endStr] = selectedPagination.split("-");
            const startIndex = parseInt(startStr, 10) - 1;
            const endIndex = parseInt(endStr, 10);
            const paginatedStocks = combinedStocks.slice(startIndex, endIndex);
            const stockData = paginatedStocks.map((item) => ({
              ...item,
              // Ensure monthlyPerformance has a default value if needed
              monthlyPerformance: item?.monthlyPerformance ?? 0,
            }));
            console.log(stockData, "filteredStocks................");
            dispatch(setDailyStocks(stockData));

            alpha = false;
          } else {
            if (count > 3) {
              dispatch(setDailyStocks([]));
              alpha = false;
            } else {
              count++;
            }
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(setHideLoader());
      }
    };

    fetchData();
    // Include `activeButton` in the dependency array to re-fetch when filter changes
  }, [
    dispatch,
    selectedSector,
    selectedAsset,
    allData,
    selectedPagination,
    activeButton, // Added dependency
  ]);

  // Handler to set the active button based on user interaction
  const handleSetActiveButton = (data) => {
    let text;
    switch (data) {
      case "day":
        text = "dailyPerformance";
        break;
      case "week":
        text = "weeklyPerformance";
        break;
      case "month":
        text = "monthlyPerformance";
        break;
      case "yoy":
        text = "yearlyPerformance";
        break;
      case "ytd":
        text = "yearToDatePerformance";
        break;
      default:
        text = data;
    }
    dispatch(setSelectedFilter(text));
  };

  return (
    <section className="bg-[#222222] relative h-[100vh] overflow-clip">
      <div className="absolute -top-2 left-5 w-full -z-5">
        <div className="flex flex-row gap-1 md:max-w-full max-w-[300px] overflow-auto">
          <HeroButton
            text="Day"
            short="day"
            color={colorScheme.success.color}
            borderColor={colorScheme.success.color}
            activeValue={activeButton}
            setActiveButton={handleSetActiveButton}
          />
          <HeroButton
            text="Week"
            short="week"
            color={colorScheme.danger.color}
            borderColor={colorScheme.danger.color}
            activeValue={activeButton}
            setActiveButton={handleSetActiveButton}
          />
          <HeroButton
            text="Month"
            short="month"
            color={colorScheme.success.color}
            borderColor={colorScheme.success.color}
            activeValue={activeButton}
            setActiveButton={handleSetActiveButton}
          />
          <HeroButton
            text="YoY"
            short="yoy"
            color={colorScheme.danger.color}
            borderColor={colorScheme.danger.color}
            activeValue={activeButton}
            setActiveButton={handleSetActiveButton}
          />
          <HeroButton
            text="YTD"
            short="ytd"
            color={colorScheme.success.color}
            borderColor={colorScheme.success.color}
            activeValue={activeButton}
            setActiveButton={handleSetActiveButton}
          />
        </div>
      </div>
      <Bubbles />
      {loading && <LoaderModal />}
    </section>
  );
};

export default BubbleSection;
