export const themeColor = {
  OB_THEME: {
    name: "Yellow + Blue",
    danger: {
      name: "yellow",
      color: "#F07A00",
      textColor: "#F07A00",
    },
    success: {
      name: "blue",
      color: "#082c7f",
      textColor: "#018FFB",
    },
  },
  RG_THEME: {
    name: "Red + Green",
    danger: { name: "red", color: "#AA3333", textColor: "#AA3333" },
    success: {
      name: "green",
      color: "#228822",
      textColor: "#39DC39",
    },
  },
};

export const graphValues = [
  { id: "close", name: "Price" },
  { id: "volume", name: "Volume" },
];
