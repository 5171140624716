import { PixiUtils } from "./pixi.utils";
import * as PIXI from "pixi.js";
import React, { useEffect, useMemo, useState } from "react";
import { BubblesUtils, appConfig } from "./bubbles.utils";
import Modal from "../Modal";
import { useSelector } from "react-redux";
import ChartData from "../Modal/ChartData";
import { bubbleContentFormator } from "../../utils/formators";

const { maxCircleSize, minCircleSize } = appConfig;

export default function Bubbles() {
  const [circles, setCircles] = useState(null);
  const appRef = React.useRef(null);

  const bubbleSort = useSelector((state) => state.Settings.selectedFilter);
  const colorScheme = useSelector((state) => state.Settings.colorScheme);
  const bubbleContents = useSelector((state) => state.Settings.bubbleContent);

  // stocksData
  const Stocks = useSelector((state) => state.Stocks.dailyStocks);
  const selectedPagination = useSelector((state) => state.Stocks.selectedPage);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(false);

  const scalingFactor = useMemo(() => {
    return BubblesUtils.getScalingFactor(Stocks, bubbleSort);
  }, [bubbleSort, Stocks, selectedPagination]);

  useEffect(() => {
    if (!Stocks) return;

    const scalingFactor = BubblesUtils.getScalingFactor(Stocks, bubbleSort);

    const shapes = BubblesUtils.generateCircles(
      Stocks,
      scalingFactor,
      bubbleSort,
      colorScheme
    );

    setCircles(shapes);

    // Debugging
    console.log(`Number of stocks: ${Stocks.length}`);
    console.log(`Number of circles generated: ${shapes.length}`);
  }, [colorScheme, bubbleSort, Stocks, selectedPagination]);

  useEffect(() => {
    if (!circles) return;

    PIXI.settings.RESOLUTION = window.devicePixelRatio;
    const app = new PIXI.Application({
      width: window.innerWidth ?? 100,
      height: window.innerHeight ?? 100,
      backgroundColor: "#222222",
      antialias: true,
      autoDensity: true,
    });

    const appContainer = appRef.current;
    appContainer?.appendChild(app.view);
    appContainer?.children[0].addEventListener("click", (e) =>
      BubblesUtils.handleEmptySpaceClick(e, circles)
    );

    const imageSprites = [];
    const textSprites = [];
    const text2Sprites = [];
    const circleGraphics = [];

    circles.forEach((circle) => {
      // Removed the conditional check to include all circles
      const container = PixiUtils.createContainer(circle);

      const circleGraphic = new PIXI.Sprite(
        PixiUtils.createGradientTexture(circle.radius * 4, circle.color)
      );

      circleGraphic.anchor.set(0.5);
      circleGraphics.push(circleGraphic);
      container.addChild(circleGraphic);

      if (circle.logoUrl) {
        const logo = PIXI.Sprite.from(circle.logoUrl); // Use the dynamic logoUrl
        logo.anchor.set(0.5);
        logo.width = circle.radius * 0.5; // Scale logo width
        logo.height = circle.radius * 0.5; // Scale logo height
        container.addChild(logo); // Add logo to the container
      }
      const text = PixiUtils.createText(circle);
      container.addChild(text);
      textSprites.push(text);

      const text2 = PixiUtils.createText2(circle, bubbleContents.value);
      container.addChild(text2);
      text2Sprites.push(text2);

      container.interactive = true;
      container.buttonMode = true;

      container.on("mousedown", () => {
        setSelectedData(circle);
        setIsModalOpen(true);
      });

      container.on("touchend", () => {
        setSelectedData(circle);
        setIsModalOpen(true);
      });

      container.on("mouseover", () => {});

      container.on("mouseout", () => {
        // Revert any hover effects if necessary
      });

      // Ensure that even if bubbleSort property is falsy, the circle is added
      app.stage.addChild(container);

      // Optional: Debugging
      // console.log(`Added bubble for stock: ${circle.name}`);
    });

    const ticker = BubblesUtils.update(
      circles,
      imageSprites,
      textSprites,
      text2Sprites,
      circleGraphics,
      bubbleContents
    );

    setTimeout(() => {
      if (app?.ticker) app.ticker.add(ticker);
      // setIsLoading(false);
    }, 200);

    return () => {
      app.ticker.remove(ticker);
      appContainer?.removeChild(app.view);
      app.destroy(true, { children: true });
    };
  }, [circles, bubbleContents, bubbleSort]);

  useEffect(() => {
    if (circles) {
      const max = maxCircleSize;
      const min = minCircleSize;
      circles.forEach((circle) => {
        if (!circle[bubbleSort] && circle[bubbleSort] !== 0) return;

        const givenFactor = scalingFactor !== Infinity ? scalingFactor : 1;

        const circleData = circle[bubbleSort] || 0; // Default to 0 if undefined

        const radius = Math.abs(Math.floor(circleData * givenFactor));

        circle.targetRadius = radius > max ? max : radius > min ? radius : min;
        circle.color =
          circle[bubbleSort] > 0
            ? colorScheme.success.name
            : colorScheme.danger.name;

        if (circle.text2 && bubbleContents?.label === "Performance") {
          circle.text2.text = bubbleContentFormator(
            bubbleSort,
            circle[bubbleSort]
          );
        } else if (circle.text2 && bubbleContents?.label !== "Performance") {
          circle.text2.text = bubbleContentFormator(
            bubbleContents?.value,
            circle[bubbleContents?.value]
          );
        }
      });
    }
  }, [bubbleSort, circles, scalingFactor, colorScheme, bubbleContents]);

  return (
    <>
      <div className="w-full overflow-hidden" ref={appRef}></div>
      {isModalOpen && (
        <Modal
          isOpen={true}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedData(null);
          }}
          data={selectedData}
        >
          <ChartData selectedData={selectedData} />
        </Modal>
      )}
    </>
  );
}
