import { combineReducers, configureStore } from "@reduxjs/toolkit";
import SettingsSlice from "./slices/SettingsSlice";
import StockSlice from "./slices/StockSlice";

const store = configureStore({
  reducer: combineReducers({
    Settings: SettingsSlice,
    Stocks: StockSlice,
  }),
});

export const { dispatch, getState } = store;

export default store;
