import { createSlice } from "@reduxjs/toolkit";
import { themeColor } from "../../utils/constants";
import { assetsOptions, bubbleContent } from "../../components/Constant";

const initialState = {
  loader: 0,
  colorScheme: themeColor.RG_THEME,
  selectedFilter: "dailyPerformance",
  bubbleContent: bubbleContent[0],
  bubbleSize: bubbleContent[0],
  loaderIs: false,
  selectedSector: "ALL SECTORS",
  selectedAsset: assetsOptions[0],
};

export const SettingsSlice = createSlice({
  name: "SettingsSlice",
  initialState,
  reducers: {
    setShowLoader: (state) => {
      state.loader += 1;
    },
    setHideLoader: (state) => {
      state.loader -= 1;
    },
    setColorScheme: (state, actions) => {
      state.colorScheme = actions.payload;
    },
    setSelectedFilter: (state, actions) => {
      state.selectedFilter = actions.payload;
    },

    setBubbleContet: (state, actions) => {
      state.bubbleContent = actions.payload;
    },
    setBubbleSize: (state, actions) => {
      state.bubbleSize = actions.payload;
    },
    setSelectedSector: (state, actions) => {
      state.selectedSector = actions.payload;
    },
    setSelectedAsset: (state, actions) => {
      state.selectedAsset = actions.payload;
    },
    setLoaderIs: (state, actions) => {
      state.loaderIs = actions.payload;
    },
    
    setAllData: (state, actions) => {
      state.allData = actions.payload;
    },
  },
});

export const {
  setShowLoader,
  setHideLoader,
  setColorScheme,
  setSelectedFilter,
  setBubbleContet,
  setBubbleSize,
  setSelectedSector,
  setSelectedAsset,
  setLoaderIs,
  setAllData
} = SettingsSlice.actions;

export default SettingsSlice.reducer;
