import React from "react";
import { useSelector } from "react-redux";

const LoaderModal = () => {
  const colorScheme = useSelector((state) => state.Settings.colorScheme);

  return (
    <div
      tabIndex="-1"
      aria-hidden="true"
      className="fixed overflow-hidden top-0 right-0 bottom-0 left-0 z-[500] flex justify-center items-center bg-black bg-opacity-80"
    >
      <div className="relative p-4 w-full max-w-sm">
        <div className="relative">
          <div className="flex flex-col items-center justify-center p-4 md:p-5 ">
            <img
              src={
                colorScheme.success.name === "green"
                  ? "./assets/secondaryLoader.svg"
                  : "./assets/primaryLoader.svg"
              }
              alt="Loader"
            />
            <p className="text-white">Loading ....</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoaderModal;
