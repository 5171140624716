import React from "react";

const SelectOptions = ({ sectionName, items, onSelect, selectedValue }) => {
  return (
    <div>
      <h3 className="text-lg font-semibold">{sectionName}</h3>
      <div className="flex items-center justify-between mt-2 gap-2 flex-wrap">
        {items?.map((item) => (
          <button
            key={`${sectionName}-${item.value}`}
            className={`text-white bg-[${
              selectedValue?.value === item.value ? "#868686" : "#5b5959"
            }] hover:bg-[#747272]  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center content-center`}
            type="button"
            onClick={() => onSelect(item)}
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectOptions;
