import { useState, useEffect, useRef } from "react";
import { FaAngleDown } from "react-icons/fa6";

const Dropdown = ({ buttonText, data, value, handleInput }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(value ?? data[0]); // Initialize with the first item
  const dropdownRef = useRef(null); // Ref to detect outside click

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle item selection
  const handleSelection = (item) => {
    if (buttonText === "Color") {
      localStorage.setItem("colorTheme", item.name);
    }
    setSelectedItem(item);
    if (handleInput) {
      handleInput(item);
    }
    toggleDropdown();
  };

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close the dropdown if clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        className="text-white bg-[#5b5959] hover:bg-[#747272] font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center content-center"
        type="button"
        onClick={toggleDropdown}
      >
        {buttonText !== "Color" && selectedItem ? (
          selectedItem.name
        ) : buttonText === "Color" ? (
          <span className="flex gap-1 items-center">
            <span
              className={`rounded-full w-[15px] h-[15px] bg-[${selectedItem.danger.color}]`}
            />
            <span
              className={`rounded-full w-[15px] h-[15px] bg-[${selectedItem.success.color}]`}
            />
            {selectedItem.name}
          </span>
        ) : (
          buttonText
        )}
        <span className="ml-2 mt-[1px]">
          <FaAngleDown />
        </span>
      </button>
      {isOpen && (
        <div className="absolute z-10 bg-[#474747] rounded-lg shadow w-44">
          <ul className="py-2 text-sm text-white">
            {data.map((item) => (
              <li
                key={item?.id ?? item?.name}
                className={`flex items-center px-4 py-2 hover:bg-[#747272] cursor-pointer ${
                  item === selectedItem ? "bg-[#747272] text-white" : ""
                }`}
                onClick={() => {
                  handleSelection(item);
                }}
              >
                {buttonText === "Color" ? (
                  <span className="flex gap-1 mr-2">
                    <span
                      className={`rounded-full w-[15px] h-[15px] bg-[${item.danger.color}]`}
                    />
                    <span
                      className={`rounded-full w-[15px] h-[15px] bg-[${item.success.color}]`}
                    />
                  </span>
                ) : null}
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
