import React, { useCallback } from "react";
import {
  assetsOptions,
  bubbleContent,
  colors,
  dummyLanguage,
} from "../Constant";
import { useSelector } from "react-redux";
import {
  setBubbleContet,
  setColorScheme,
  setSelectedAsset,
} from "../../reducers/slices/SettingsSlice";
import { IoMdDownload } from "react-icons/io";
import { dispatch } from "../../reducers/configureStore";
import Modal from "../Modal";
import Dropdown from "../Dropdown";
import SelectOptions from "./components/SelectOptions";
import {
  setSelectedPagination
} from "../../reducers/slices/StockSlice";

const Settings = ({ onClose }) => {

  const handleColorShcemeChange = useCallback((data) => {
    dispatch(setColorScheme(data));
    localStorage.setItem("THEME", JSON.stringify(data));
  }, []);
  const handleBubbleContentChange = useCallback((data) => {
    dispatch(setBubbleContet(data));
  }, []);
  const colorScheme = useSelector((state) => state.Settings.colorScheme);
  const selectedBubbleContent = useSelector(
    (state) => state.Settings.bubbleContent
  );
  const selectedAsset = useSelector((state) => state.Settings.selectedAsset);
  const handleAssetChange = useCallback((data) => {
    dispatch(setSelectedAsset(data));
          dispatch(setSelectedPagination( "1-100"));

    localStorage.setItem("THEME", JSON.stringify(data));
  }, []);
  return (
    <Modal isOpen={true} onClose={onClose}>
      <div className="p-8 space-y-10">
        <div className="space-y-6">
          <SelectOptions
            sectionName="Bubble Content"
            items={bubbleContent}
            selectedValue={selectedBubbleContent}
            onSelect={handleBubbleContentChange}
          />
          {/* <SelectOptions
            sectionName="Bubble Size"
            items={bubbleContent}
            selectedValue={bubbleSize}
            onSelect={handleBubbleSizeChange}
          /> */}
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold">Asset</h3>
            <Dropdown
              buttonText="Asset"
              data={assetsOptions}
              value={selectedAsset}
              handleInput={handleAssetChange}
            />
          </div>
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold">Language</h3>
            <Dropdown buttonText="Language" data={dummyLanguage} />
          </div>
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold">Colors</h3>
            <Dropdown
              buttonText="Color"
              data={colors}
              value={colorScheme}
              handleInput={handleColorShcemeChange}
            />
          </div>
        </div>

        {/* <div className="space-y-4">
          <button className="w-full text-white bg-[#5b5959] hover:bg-[#747272] font-medium rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center">
            <span className="mr-2 mt-[1px]">
              <IoMdDownload />
            </span>
            Download Terms & Conditions
          </button>
          <button className="w-full text-white bg-[#5b5959] hover:bg-[#747272]  font-medium rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center">
            <span className="mr-2 mt-[1px]">
              <IoMdDownload />
            </span>
            Download User Manual
          </button>
        </div> */}
      </div>
    </Modal>
  );
};

export default Settings;
