import { formatCurrency } from "../../../utils/formators";

const StockStatsCard = ({ data }) => {
  const filters = [
    {
      id: "rank",
      label: "Rank",
      value: data?.rank,
    },
    {
      id: "marketCap",
      label: "Market Cap",
      value: data?.marketCap
        ? `₦${formatCurrency(data?.marketCap).replace("NGN", "")}`
        : "--",
    },
    {
      id: "volume",
      label: "24h Volume",
      value: data?.volume
        ? `Qty.${formatCurrency(data?.volume).replace("NGN", "")}`
        : "--",
    },
  ];

  return (
    <div className="text-sm font-medium text-center text-white p-2">
      <ul className="flex flex-wrap justify-around">
        {filters.map(({ id, label, value }) => {
          return (
            <li key={id} className={`inline-block px-2 py-2`}>
              <button className={`px-1 py-1 rounded-lg`}>{label}</button>
              <p className={`font-bold m-0`}>{value}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default StockStatsCard;
