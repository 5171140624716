import { useSelector } from "react-redux";
import { roundToOneDecimal } from "../../utils/formators";

const Tabs = ({
  data,
  selectedFilter,
  setSelectedFilter,
  allTimeData,
  valueFilter,
  stockVolumes,
}) => {
  const colorScheme = useSelector((state) => state.Settings.colorScheme);

  const filters = [
    {
      id: "weeklyPerformance",
      label: "Week",
      value: data?.weeklyPerformance,
      volume: stockVolumes?.weeklyVolume,
    },
    {
      id: "monthlyPerformance",
      label: "Month",
      value: data?.monthlyPerformance,
      volume: stockVolumes?.monthlyVolume,
    },
    {
      id: "yearlyPerformance",
      label: "Year",
      value: data?.yearlyPerformance,
      volume: stockVolumes?.yearlyVolume,
    },
    {
      id: "allTimeData",
      label: "Max",
      value: allTimeData,
      volume: stockVolumes?.allTimeVolume,
    },
  ];

  const getPerformanceValue = (filterId, dataValue, volume) => {
    return (
      roundToOneDecimal(valueFilter.id === "volume" ? volume : dataValue) ?? "-"
    );
  };

  const getPerformanceClass = (value) => {
    return value >= 0
      ? colorScheme.success.textColor
      : colorScheme.danger.textColor;
  };

  const handleItemClick = (index) => {
    setSelectedFilter(index);
  };

  return (
    <div className="text-sm font-medium text-center text-white p-2">
      <ul className="flex flex-wrap justify-between -mb-px">
        {filters.map(({ id, label, value, volume }) => {
          const performanceValue = getPerformanceValue(id, value, volume);
          const isSelected = selectedFilter === id;
          const textColor = getPerformanceClass(performanceValue);

          return (
            <li
              key={id}
              className={`inline-block px-8 py-2 cursor-pointer hover:bg-[#585858] rounded-lg ${
                isSelected ? "bg-[#585858]" : ""
              }`}
              onClick={() => handleItemClick(id)}
            >
              <button
                className={`px-2 py-1 rounded-lg ${
                  isSelected ? `bg-[${textColor}]` : ""
                }`}
              >
                {label}
              </button>
              <p className={`text-lg m-0 text-[${textColor}]`}>
                {performanceValue}%
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Tabs;
