const HeroButton = ({
  text,
  color,
  borderColor,
  activeValue,
  setActiveButton,
  short,
}) => {
  const isActive = Boolean(
    short ? short === activeValue : text.toLowerCase() === activeValue
  );
  return (
    <button
      type="button"
      className={`pb-2.5 pt-4 px-5 mb-4 text-sm font-medium text-white focus:outline-none ${
        isActive ? `bg-[${color}] bg-opacity-70` : "bg-[#444444]"
      } rounded-lg border-x-2 border-b-2 hover:bg-[${color}]`}
      style={{ borderColor: borderColor }}
      onClick={() => setActiveButton(short ?? text.toLowerCase())}
    >
      {text}
    </button>
  );
};

export default HeroButton;
