import React, { useCallback, useMemo } from "react";
import ApexCharts from "react-apexcharts";
import { CHART_DUMMY_DATA } from "../Constant";
import { useSelector } from "react-redux";
import { bubbleContentFormator } from "../../utils/formators";

const AreaChart = ({ data = [], graphValue }) => {
  const colorScheme = useSelector((state) => state.Settings.colorScheme);

  const calculateData = useCallback(
    (stock) => {
      const date = new Date(stock?.tradeDate).getTime();
      if (date) {
        return [date, stock[graphValue?.id]];
      }
      return null;
    },
    [graphValue]
  );

  const selectedData = useMemo(() => {
    if (data.length) {
      return data
        .sort((a, b) => new Date(a.tradeDate) - new Date(b.tradeDate))
        .map(calculateData)
        .filter(Boolean); // Filter out any null values
    }
    return CHART_DUMMY_DATA;
  }, [data, calculateData]);

  const minYValue = useMemo(
    () => Math.min(...selectedData.map((item) => item[1])),
    [selectedData]
  );

  const maxYValue = useMemo(
    () => Math.max(...selectedData.map((item) => item[1])),
    [selectedData]
  );

  const chartData = useMemo(
    () => ({
      series: [{ name: graphValue.name, data: selectedData }],
      options: {
        chart: {
          id: "area-datetime",
          type: "area",
          toolbar: {
            show: false,
          },
          zoom: {
            autoScaleYaxis: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          min:
            selectedData[0]?.[0] || new Date(CHART_DUMMY_DATA[0][0]).getTime(),
          tickAmount: 6,
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        annotations: {
          yaxis: [
            {
              y: minYValue,
              borderColor: `${colorScheme.danger.textColor}`,
              label: {
                borderColor: `${colorScheme.danger.textColor}`,
                style: {
                  color: "#fff",
                  background: `${colorScheme.danger.textColor}`,
                  fontSize: "15px",
                },
                text: `${bubbleContentFormator(graphValue?.id, minYValue)}`,
              },
            },
            {
              y: maxYValue,
              borderColor: `${colorScheme.success.textColor}`,
              label: {
                borderColor: `${colorScheme.success.textColor}`,
                style: {
                  color: "#fff",
                  background: `${colorScheme.success.textColor}`,
                  fontSize: "15px",
                },
                text: `${bubbleContentFormator(graphValue?.id, maxYValue)}`,
              },
            },
          ],
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
          style: {
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
          },
          onDatasetHover: {
            highlightDataSeries: true,
          },
        },
        fill: {
          type: "gradient",
          colors: ["#0d5edb"],
        },
        grid: {
          show: false,
        },
      },
    }),
    [selectedData, minYValue, maxYValue, colorScheme, graphValue]
  );

  return (
    <div id="chart">
      <ApexCharts
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={260}
      />
    </div>
  );
};

export default AreaChart;
