import React, { useEffect, useState, useMemo } from "react";
import AreaChart from "../Charts/AreaChart";
import Tabs from "../Tabs";
import axios from "axios";
import moment from "moment";
import { graphValues } from "../../utils/constants";
import Dropdown from "../Dropdown";
import { useSelector } from "react-redux";
import StockStatsCard from "./components/StockStatsCard";

const ChartData = ({ selectedData }) => {
  const [selectedFilterData, setSelectedFilterData] =
    useState("weeklyPerformance");
  const [stockData, setStockData] = useState([]);
  const [allTimeData, setAllTimeData] = useState(0);
  const [graphValue, setGraphValue] = useState(graphValues[0]);
  const [loading, setIsLoading] = useState(false);
  const [stockVolumes, setStockVolumes] = useState({
    weeklyVolume: 0,
    yearlyVolume: 0,
    monthlyVolume: 0,
    allTimeVolume: 0,
  });

  const colorScheme = useSelector((state) => state.Settings.colorScheme);

  const calculateVolume = (filtered) => {
    if (!filtered.length) return 0;
    const initialVolume = filtered[filtered.length - 1]?.volume ?? 0;
    const finalVolume = filtered[0]?.volume ?? 0;
    const divident = initialVolume - finalVolume;
    const diviser = finalVolume !== 0 ? finalVolume : 1;
    const data = divident / diviser;
    return data * 100;
  };

  const filterDataByRange = (stockData, startDate, endDate) =>
    stockData.filter((stock) =>
      moment(stock.tradeDate).isBetween(startDate, endDate, undefined, "[]")
    );

  useEffect(() => {
    const fetchStockData = async () => {
      try {
        setIsLoading(true);
        const filters = {
          symbol: selectedData?.stockName ?? selectedData.symbol,
        };
        const { data: response } = await axios.post("api/stocks/list", {
          filters,
        });

        if (response.data) {
          const stockData = response.data.sort(
            (a, b) => new Date(a.tradeDate) - new Date(b.tradeDate)
          );
          setStockData(stockData);

          const endDate = moment(selectedData.tradeDate).startOf("day");

          const timeRanges = [
            { key: "weeklyVolume", days: 7 },
            { key: "monthlyVolume", days: 30 },
            { key: "yearlyVolume", days: 365 },
          ];

          const volumeUpdates = timeRanges.reduce((acc, range) => {
            const startDate = moment(endDate)
              .subtract(range.days, "days")
              .startOf("day");
            const filtered = filterDataByRange(stockData, startDate, endDate);
            acc[range.key] = calculateVolume(filtered);
            return acc;
          }, {});

          const allTimeVolume = calculateVolume(stockData);
          const allTimePerformance =
            ((stockData[stockData.length - 1].close - stockData[0].close) /
              stockData[0].close) *
            100;

          setStockVolumes({
            ...volumeUpdates,
            allTimeVolume,
          });

          setAllTimeData(allTimePerformance);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStockData();
  }, [selectedData]);

  const filteredStockData = useMemo(() => {
    if (!stockData.length) return [];
    const endDate = moment(selectedData.tradeDate).startOf("day");
    let startDate = null;

    if (selectedFilterData === "weeklyPerformance") {
      startDate = moment(endDate).subtract(7, "days").startOf("day");
    } else if (selectedFilterData === "monthlyPerformance") {
      startDate = moment(endDate).subtract(30, "days").startOf("day");
    } else if (selectedFilterData === "yearlyPerformance") {
      startDate = moment(endDate).subtract(365, "days").startOf("day");
    }
    if (startDate && endDate)
      return filterDataByRange(stockData, startDate, endDate);
    else return stockData;
  }, [selectedFilterData, stockData, selectedData]);

  return (
    <div>
      <div className="flex items-center justify-end p-4 pb-0 text-white">
        <Dropdown
          buttonText="GraphValue"
          data={graphValues}
          value={graphValue}
          handleInput={(data) => setGraphValue(data)}
        />
      </div>
      {!loading ? (
        <>
          <StockStatsCard data={selectedData} />
          <AreaChart
            data={filteredStockData}
            selectedFilter={selectedFilterData}
            graphValue={graphValue}
          />
        </>
      ) : (
        <div className="flex flex-col items-center justify-center p-4 md:p-5 ">
          <img
            src={
              colorScheme.success.name === "green"
                ? "./assets/secondaryLoader.svg"
                : "./assets/primaryLoader.svg"
            }
            className="w-[20%]"
            alt="Loader"
          />
          <p className="text-white">Loading ....</p>
        </div>
      )}

      <Tabs
        data={selectedData}
        selectedFilter={selectedFilterData}
        setSelectedFilter={setSelectedFilterData}
        valueFilter={graphValue}
        stockVolumes={stockVolumes}
        allTimeData={allTimeData}
      />
    </div>
  );
};

export default ChartData;
