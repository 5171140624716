import React, { useState, useEffect, useMemo, useRef } from "react";
import Modal from "./Modal";
import { useSelector, useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import ChartData from "./Modal/ChartData";
import { formatCurrency, roundToOneDecimal } from "../utils/formators";
import { useWebSocket } from '../WebSocketContext';
import { setPagination } from "../reducers/slices/StockSlice";

import { setAllData,setLoaderIs } from "../reducers/slices/SettingsSlice";
import { isEqual } from 'lodash'; // Import isEqual for deep comparison

const Table = () => {
  // Local state for modal management
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  // WebSocket connection
  const socket = useWebSocket();

  // Local state for stocks and loading status
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(true);

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux selectors
  const selectedAsset = useSelector((state) => state.Settings.selectedAsset);

  const selectedSector = useSelector((state) => state.Settings.selectedSector);
  const colorScheme = useSelector((state) => state.Settings.colorScheme);
  const allData = useSelector((state) => state.Settings.allData);
  const selectedPagination = useSelector((state) => state.Stocks.selectedPage);

  console.log("Selected Sector:", selectedSector); 

  const prevFilteredStocksRef = useRef([]);

  // Effect to handle WebSocket messages
  useEffect(() => {
    if (!socket) return;
    dispatch(setLoaderIs(true));

    const handleMessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log("Received data:", data);
        // const modifiedData = data.map((item, index) => {
        //   // Add 'CONSTRUCTION/REAL ESTATE' sector to the first three items
        //   if (index < 10) {
        //     return { ...item, Sector: 'CONSTRUCTION/REAL ESTATE' };
        //   }
        //   // Add 'FINANCIAL SERVICES' sector to all other items
        //   return { ...item, Sector: 'FINANCIAL SERVICES' };
        // });

        // const afterremove = data.filter(obj => {
        //   // Check if any key matches the condition
        
        //   return Object.keys(obj).some(key => 
        //     key.includes('Symbol') && /\[.*?\]/.test(obj[key])
        //   );
         
        // });
        // console.log(afterremove,"afterremove")
        
        const cleanedArray = data.filter(obj => {
          // Check if any key matches the condition
        
          return !Object.keys(obj).some(key => 
            key.includes('Symbol') && /\[.*?\]/.test(obj[key])
          );
         
        });
       console.log(cleanedArray,"cleanedArray")
        setStocks(cleanedArray );
        setLoading(false);
        dispatch(setLoaderIs(false))

      } catch (error) {
        dispatch(setLoaderIs(false))

        console.error("Error parsing WebSocket data:", error);
      }
    };

    socket.addEventListener("message", handleMessage);

    return () => {
      socket.removeEventListener("message", handleMessage);
      socket.close();
    };
  }, [socket]);

  const filteredStocks = useMemo(() => {
    console.log("Filtering stocks with:", {
      selectedAsset: selectedAsset.name,
      selectedSector,
      totalStocks: stocks.length,
    });

    return stocks.filter((item) => {
      const asset = item.Asset ? item.Asset.toUpperCase().trim() : "";
      const sector = item.Sector ? item.Sector.toLowerCase().trim() : "";
      const selectedSectorLower = selectedSector ? selectedSector.toLowerCase().trim() : "";

      let assetMatch = false;
      let sectorMatch = false;

      // Determine asset match
      switch (selectedAsset.name.toUpperCase()) {
        case "EQUITY":
          assetMatch = asset === "EQUITY";
          break;
        case "DEBT":
          assetMatch = asset === "DEBT";
          break;
        case "ETF":
          assetMatch = asset === "ETF";
          break;
        case "ALL":
          assetMatch = true;
          break;
        default:
          assetMatch = false;
      }

      // Determine sector match
      if (selectedAsset.name.toUpperCase() === "EQUITY") {
        if (selectedSectorLower === "all sectors") {
          sectorMatch = true;
        } else {
          sectorMatch = sector === selectedSectorLower;
        }
      } else {
        // For non-Equity assets, sector might not be relevant
        sectorMatch = true;
      }

      return assetMatch && sectorMatch;
    });
  }, [stocks, selectedAsset, selectedSector]);

  // Effect to dispatch pagination and allData when filteredStocks changes
  useEffect(() => {
    console.log(`Filtered stocks count: ${filteredStocks.length}`); // Debugging log
    dispatch(setPagination(filteredStocks.length));
    // Compare previous and current filteredStocks
    if (!isEqual(prevFilteredStocksRef.current, filteredStocks)) {
      dispatch(setAllData(filteredStocks));
      // Update the ref with the current filteredStocks
      prevFilteredStocksRef.current = filteredStocks;
    }
  }, [filteredStocks, dispatch]);

  // Handlers to open and close the modal
  const openModal = (data) => {
    setIsModalOpen(true);
    setSelectedData(data);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedData(null);
  };

  // Pagination logic
  const [startStr, endStr] = selectedPagination.split('-');
  const startIndex = Math.max(0, parseInt(startStr, 10) - 1); // Adjust for zero-based index and prevent negative
  const endIndex = Math.min(parseInt(endStr, 10), filteredStocks.length); // Prevent exceeding array length
  const paginatedStocks = filteredStocks.slice(startIndex, endIndex);

  return (
    <section
      className="bg-[#222222] h-auto flex justify-center flex-col items-center"
      id="table"
    >
      <PerfectScrollbar
        options={{ suppressScrollX: false, suppressScrollY: true }}
        className="w-[90vw] relative overflow-x-auto"
      >
        <table className="w-full text-sm text-left rtl:text-right text-white">
          <thead className="text-white uppercase bg-[#3D3D3D] text-lg">
            <tr>
              {/* Table Headers */}
              <th scope="col" className="px-6 py-3">#</th>
              <th scope="col" className=" py-3" style={{ minWidth: '165px' }}>Trade Date</th>
              {/* <th scope="col" className="px-6 py-3">Sector</th> */}
              <th scope="col" className="px-6 py-3">Stock</th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">Last Price</th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">Opening Price</th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">Market Cap</th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">Daily</th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">Weekly</th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">Monthly</th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">YoY</th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">YTD</th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">24h Volume</th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">Value</th>
            </tr>
          </thead>
          <tbody className="text-lg">
            {loading ? (
              <tr>
                <td colSpan={14} className="text-center px-6 py-4">Loading...</td>
              </tr>
            ) : paginatedStocks.length > 0 ? (
              paginatedStocks.map((item, index) => (
                <tr
                  key={item.id || index} // Use a unique key if available
                  className="bg-[#222222] cursor-pointer border-b-[0.5px]"
                  onClick={() => openModal(item)}
                >
                  <th
                    scope="row"
                    className="px-6 py-2 font-medium whitespace-nowrap"
                  >
                    {startIndex + index + 1}
                  </th>
                  <td style={{ minWidth: '165px' }}>  {new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(new Date(item.TradeDate))}</td>
                  {/* <td>{item.Sector}</td> */}
                  <td>
                    <div className="bg-[#3D3D3D] w-max px-4 py-2 rounded-md flex font-semibold items-center">
                      {item.Symbol}
                    </div>
                  </td>
                  <td className="px-6 py-2">
                    ₦ {formatCurrency(item.Close).replace("NGN", "")}
                  </td>
                  <td className="px-6 py-2">
                    ₦ {formatCurrency(item.Open).replace("NGN", "")}
                  </td>
                  <td className="px-6 py-2">
                    {item.marketCap ? "₦" : ""} {formatCurrency(item.marketCap) ?? "--"}
                  </td>
                  {/* Performance Cells */}
                  {["dailyPerformance", "weeklyPerformance", "monthlyPerformance", "yearlyPerformance", "yearlyToDatePerformance"].map((perf, idx) => (
                    <td
                      key={idx}
                      className={`px-6 py-2 ${
                        roundToOneDecimal(item[perf]) < 0
                          ?  `bg-[${colorScheme.danger.color}]`
                          : roundToOneDecimal(item[perf]) === 0
                          ? `bg-none`
                          : roundToOneDecimal(item[perf]) == null 
                          ? `bg-none` 
                          : `bg-[${colorScheme.success.color}]`
                      }`}
                    >
                      {roundToOneDecimal(item[perf]) ?? 0} %
                    </td>
                  ))}
                  <td className="px-6 py-2">
                    Qty. {formatCurrency(item.Volume).replace("NGN", "")}
                  </td>
                  <td className="px-6 py-2">
                    ₦{" "}
                    {formatCurrency(item.Volume * item.Close).replace(
                      "NGN",
                      ""
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={14} className="text-center px-6 py-4">
                  No data available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </PerfectScrollbar>

      {/* Modal for displaying detailed data */}
      <Modal isOpen={isModalOpen} onClose={closeModal} data={selectedData}>
        <ChartData selectedData={selectedData} />
      </Modal>
    </section>
  );
};

export default Table;
