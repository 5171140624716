import { isNumber, isString } from "lodash";

export const formatCurrency = (value, locale = "en-US", currency = "NGN") => {
  if (value) {
    let valuegenerated = value;
    let lastValue = "";
    if (value >= 1000 && value < 1000000) {
      valuegenerated = value / 1000;
      lastValue = " K";
    } else if (value >= 1000000 && value < 1000000000) {
      valuegenerated = value / 1000000;
      lastValue = " M";
    } else if (value >= 1000000000 && value < 1000000000000) {
      valuegenerated = value / 1000000000;
      lastValue = " B";
    } else if (value >= 1000000000000 && value < 1000000000000000) {
      valuegenerated = value / 1000000000000;
      lastValue = " Qd";
    } else if (value >= 1000000000000000 && value < 1000000000000000000) {
      valuegenerated = value / 1000000000000000;
      lastValue = " Qi";
    } else if (value >= 1000000000000000000 && value < 1000000000000000000000) {
      valuegenerated = value / 1000000000000000000;
      lastValue = " Sx";
    } else if (
      value >= 1000000000000000000000 &&
      value < 1000000000000000000000000
    ) {
      valuegenerated = value / 1000000000000000000000;
      lastValue = " Sp";
    } else if (
      value >= 1000000000000000000000000 &&
      value < 1000000000000000000000000000
    ) {
      valuegenerated = value / 1000000000000000000000000;
      lastValue = " Oc";
    } else if (
      value >= 1000000000000000000000000000 &&
      value < 1000000000000000000000000000000
    ) {
      valuegenerated = value / 1000000000000000000000000000;
      lastValue = " No";
    } else if (value >= 1000000000000000000000000000000) {
      valuegenerated = value / 1000000000000000000000000000000;
      lastValue = " Dc";
    }
    return (
      new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(valuegenerated) + lastValue
    );
  }
  return "0";
};

export const bubbleContentFormator = (content, value) => {
  if (isString(content))
    return `${
      content === "volume"
        ? "qty"
        : !content?.includes("Performance")
        ? "₦"
        : ""
    }${
      content?.includes("Performance")
        ? Number(value?.toFixed(1))
        : formatCurrency(Number(value?.toFixed(1))).replaceAll("NGN", "")
    }${content?.includes("Performance") ? "%" : ""}`;
  else {
    return `${
      content?.value === "volume"
        ? "qty"
        : !content?.value?.includes("Performance")
        ? "₦"
        : ""
    }${
      content?.value?.includes("Performance")
        ? Number(value?.toFixed(1))
        : formatCurrency(Number(value?.toFixed(1))).replaceAll("NGN", "")
    }${content?.value?.includes("Performance") ? "%" : ""}`;
  }
};

export const roundToOneDecimal = (value) => {
  if (isNumber(value)) return Number(value?.toFixed(1));
};
